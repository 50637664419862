
import { Tooltip, Modal } from '/js/vendor/bootstrap.custom.js';
import { persistStore, userDataStore, cartStore, modalsListStore } from "/js/store/index.js"
import { userApi, cartApi } from "/js/api.js";
import { Cookies } from '/js/utils.js';
import notifs from '/js/notifications.js'

import i18n from '/js/i18n.js'


/* Функции для покупок, избранного и пр. */
const schGlobalApi = {

	addToCart: (proposal_id, event) => {
		event.preventDefault();

		cartStore.actions.addItem(proposal_id).then(data => {
			if (data?.result === 'Success') {
				let target = event?.target.closest('button')
				if (target) {
					target.classList.remove('btn-primary')
					target.classList.add('btn-light')

					if (target.hasAttribute('data-bs-original-title')) {
						Tooltip.getInstance(target)?.dispose()
						new Tooltip(target, { title: i18n.t('cart.added_short') })
					}
				};
			}
		});
	},

	changeFav(proposal_id, item_type, event) {
		event.preventDefault();
		proposal_id && userApi.change_fav(proposal_id, item_type).then(data => {
			if (data) {
				if (data.status === 'Success') {
					let target = event?.target.closest('button')

					if (target) {
						target.classList.toggle('active')

						if (target.hasAttribute('data-bs-original-title')) {
							Tooltip.getInstance(target)?.dispose()
							new Tooltip(target)
						}
					};
				}
				else if (data.status === 'Error') {
					notifs.enqueue({ severity: 'danger', title: data.message })
				}
			}
		})
	},

	openPriceOffer (id, type, currOffer) {
		let modal = document.getElementById('priceOfferModal')
		let offer = typeof currOffer === 'string' ? JSON.parse(currOffer) : currOffer

		Modal.getOrCreateInstance(modal).show()

		offer = Object.assign({}, offer)

		offer.itemId = id
		offer.itemType = type
		modalsListStore.focus(modal.id).focus('props').set(offer)

		modal.addEventListener('hidden.bs.modal', (e) => {
			let result = modalsListStore.focus(modal.id).focus('result').value
			// if (result) setTimeout(() => location.reload(), 1000);
		}, {
			once: true
		})
	},

	logout () {
		persistStore.focus(s => s.token).set('')
		userDataStore.set({})
		Cookies.remove('PHPSESSID', { path: '/', sameSite: 'lax' })
		setTimeout(() => {
			window.location.href = '/'
		}, 200);
	}

}

globalThis.schGlobalApi = schGlobalApi
